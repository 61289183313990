<script setup lang="ts">
import AgentTabs from './AgentTabs.vue';
</script>
<template>
  <div
    class="px-8 py-1 sticky top-0 left-0 w-full grid border-b place-items-center bg-white border-gray-200 z-50 grid-cols-[1fr,1fr,1fr]"
  >
    <!-- LEFT ACTIONS -->
    <div class="flex gap-5 justify-self-start">
      <!-- EXIT -->
      <AppBackButton to="/agents" text="Exit" replace class="text-gray-600" />
      <!-- SPELL LABEL -->
      <div class="flex flex-row justify-center gap-2">
        <UIcon name="i-respell-sdr-agent" class="w-8 h-8 shrink-0" />
        <p class="subtitle shrink-0">SDR Agent</p>
      </div>
    </div>

    <AgentTabs class="place-self-center" />

    <!-- RIGHT ACTIONS -->

    <UButton
      icon="i-ph-dots-three-outline-vertical-fill"
      size="md"
      disabled
      color="white"
      variant="solid"
      class="ml-auto"
      :ui="{ icon: { size: { xl: 'h-5 w-5' } } }"
    />
  </div>
</template>
