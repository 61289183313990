<script setup lang="ts">
const tabs = [
  {
    label: 'Configure',
    icon: 'i-ph-faders-horizontal',
    replace: true,
    to: '/agent/sdr/configure',
  },
  {
    label: 'Analytics',
    icon: 'i-ph-chart-bar',
    replace: true,
    to: {
      name: 'agent.analytics',
    },
  },
  {
    label: 'Campaigns',
    icon: 'i-ph-paper-plane-right',
    replace: true,
    to: '/agent/sdr/campaigns',
  },
];
</script>

<template>
  <UHorizontalNavigation
    :links="tabs"
    :ui="{
      wrapper: 'w-fit',
      container: 'bg-gray-100 rounded-lg px-1 gap-1',
      active: 'before:bg-white',
      base: 'py-2 px-4',
      before: 'before:inset-y-1',
      after: 'after:h-0',
    }"
  >
    <template #default="{ link }">
      <span class="hidden md:block relative truncate">{{ link.label }}</span>
    </template>
  </UHorizontalNavigation>
</template>
