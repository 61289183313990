<script setup lang="ts">
import Info from '~/pages/agents/tabs/config/Info.vue';
import Tools from '~/pages/agents/tabs/config/Tools.vue';

const modal = useModal();
const agentStore = useAgentsStore();

const activeStep = ref(0);
const modalBody = ref(null);

const { arrivedState } = useScroll(modalBody);

watch(activeStep, (newVal) => {
  if (newVal === 3) {
    setTimeout(() => {
      if (activeStep.value === 3) {
        activeStep.value += 1;
      }
    }, 2500);
  }
});

const steps = computed(() => [
  {
    title: 'Welcome to your new SDR Agent!',
    description:
      'Before you get started setting up your agent, we recommend you watch this short video to learn more about how it works.',
  },
  {
    title: 'Provide information about your business',
    description:
      'Complete the following questions to fine-tune your agent. The agent will use this information to qualify prospects and write targeted emails.',
  },
  {
    title: 'Connect the agent with your tools',
    description:
      'This is a required step which will enable the agent to do work for you on the background',
  },
  {},
  {
    title: `Congrats on setting up your SDR Agent for
          ${agentStore.configState.companyName ?? 'your company'}!`,
    description:
      'Now go ahead and launch your first campaign to start generating leads',
  },
]);

const handleComplete = async () => {
  await navigateTo({
    name: 'agent.campaigns',
  });
  modal.close();
};
</script>
<template>
  <UModal :ui="{ width: 'sm:max-w-3xl' }" prevent-close>
    <UCard
      :ui="{
        base: 'w-full',
        divide: activeStep === 3 ? 'divide-y-0' : 'divide-y',
        body: { padding: '!py-0', base: 'relative' },
        header: { base: 'flex flex-col gap-5' },
      }"
    >
      <template #header>
        <UProgress :value="activeStep + 1" :max="5" size="sm" class="w-1/2" />

        <span class="flex gap-2 dimmed body justify-start w-full">
          <UIcon name="i-ph-sparkle" />
          <p class="uppercase font-bold">Set up your SDR agent</p>
        </span>
      </template>

      <div
        ref="modalBody"
        class="w-full h-full overflow-y-scroll flex flex-col gap-6 max-h-[60vh] justify-start py-4"
      >
        <!-- OVERFLOW SHADOW -->
        <div
          v-if="activeStep === 1"
          class="absolute inset-x-0 top-0 h-2 bg-gradient-to-b from-black transition-opacity z-50"
          :class="arrivedState.top ? 'opacity-0' : 'opacity-20'"
        />

        <span v-if="activeStep !== 3" class="flex flex-col items-start mr-auto">
          <p class="title">{{ steps[activeStep].title }}</p>
          <p class="body dimmed">{{ steps[activeStep].description }}</p>
        </span>

        <iframe
          v-if="activeStep === 0"
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/A7CigMSnFjM?si=L0tKPiVrYNRnlJKx"
          title="Respell SDR Agent Demo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        />

        <Info v-else-if="activeStep === 1" class="w-full" />

        <Tools v-else-if="activeStep === 2" class="w-full" />

        <!-- TODO: Add review settings -->

        <div v-else-if="activeStep === 3" class="flex flex-col gap-2">
          <UIcon
            name="i-ph-circle-notch-bold"
            class="animate-spin text-primary-500 w-8 h-8"
          />
          <p class="title">Generating your assistant</p>
          <p class="body dimmed">This is gonna be quick</p>
        </div>

        <UButton
          v-else-if="activeStep === 4"
          size="xl"
          color="white"
          :ui="{ rounded: 'rounded-lg', padding: { xl: 'p-5' } }"
          @click="handleComplete"
        >
          <template #leading>
            <UIcon name="i-respell-sdr-agent" class="w-8 h-8 shrink-0" />
          </template>
          <div class="flex flex-col items-start px-s">
            <p class="title">
              {{ agentStore.configState.companyName ?? '' }} SDR Agent
            </p>

            <p class="caption dimmed">Click to start using your agent</p>
          </div>
          <template #trailing>
            <UIcon name="i-ph-caret-right" class="w-5 h-5" />
          </template>
        </UButton>

        <!-- OVERFLOW SHADOW -->
        <div
          v-if="activeStep === 1"
          class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-t from-black transition-opacity"
          :class="arrivedState.bottom ? 'opacity-0' : 'opacity-20'"
        />
      </div>

      <template #footer>
        <div v-if="activeStep === 4" class="flex gap-4 w-full">
          <!-- BACK BUTTON -->
          <UButton
            label="Edit assistant settings"
            variant="ghost"
            color="gray"
            leading-icon="i-ph-gear"
            size="lg"
            @click="modal.close"
          />
          <!-- NEXT BUTTON -->
          <UButton
            label="Start using agent"
            variant="solid"
            color="primary"
            trailing-icon="i-ph-arrow-right"
            size="lg"
            class="ml-auto"
            @click="handleComplete"
          />
        </div>
        <div
          v-else
          class="flex gap-4 w-full"
          :class="{ invisible: activeStep === 3 }"
        >
          <!-- BACK BUTTON -->
          <UButton
            v-if="activeStep > 0"
            label="Back"
            variant="ghost"
            color="gray"
            size="lg"
            @click="activeStep -= 1"
          />
          <UButton
            label="Next"
            variant="solid"
            color="primary"
            size="lg"
            trailing-icon="i-ph-arrow-right"
            class="ml-auto"
            @click="activeStep += 1"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>
